import { slugs } from '@/constants'
import { twentyThreeBlast } from './23-blast'
import { the1916Project } from './1916-project'
import { aWeekAwaySeries } from './a-week-away-series'
import { afterDeath } from './afterDeath'
import { angelGuild } from './angel-guild'
import { angelStudios } from './angel-studios'
import { axiom } from './axiom'
import { bethlehem } from './bethlehem'
import { betweenBorders } from './between-borders'
import { beyondTheMedal } from './beyond-the-medal'
import { bonhoeffer } from './bonhoeffer'
import { braveTheDark } from './brave-the-dark'
import { breakingIntoBeautiful } from './breaking-into-beautiful'
import { cabrini } from './cabrini'
import { david } from './david'
import { dryBar } from './dry-bar-best-of'
import { dryBarToons } from './dry-bar-toons'
import { fablehaven } from './fablehaven'
import { faithOfAngels } from './faith-of-angels'
import { fellowship } from './fellowship'
import { forKingAndCountryADrummerBoyChristmasLive } from './for-king-and-country-a-drummer-boy-christmas-live'
import { freelancers } from './freelancers'
import { gabrielAndTheGuardians } from './gabriel-and-the-guardians'
import { theGardenCartoon } from './garden-cartoon'
import { godMadeCrab } from './god-made-crab'
import { godspeed } from './godspeed'
import { goodThings } from './good-things'
import { theHidingPlace } from './hiding-place'
import { hisOnlySon } from './his-only-son'
import { homeToHarmony } from './home-to-harmony'
import { homestead } from './homestead'
import { homesteadFamilySurvival } from './homestead-family-survival'
import { homesteadSeries } from './homestead-series'
import { hoovey } from './hoovey'
import { inLawfullyYours } from './in-lawfully-yours'
import { insideAngelStudios } from './inside-angel-studios'
import { jacob } from './jacob'
import { jungleBeat } from './jungle-beat'
import { justLetGo } from './just-let-go'
import { kingOfKings } from './king-of-kings'
import { theLastChampion } from './last-champion'
import { theLastRodeo } from './last-rodeo'
import { lightwise } from './lightwise'
import { likeDandelionDust } from './like-dandelion-dust'
import { liveNotByLies } from './live-not-by-lies'
import { lostOnAMountainInMaine } from './lost-on-a-mountain-in-maine'
import { miracles } from './miracles'
import { mosaicChurch } from './mosaic-church'
import { myBrothersKeeper } from './my-brothers-keeper'
import { offTheCouchIntoCreation } from './off-the-couch-into-creation'
import { oldManAndTheDunes } from './old-man-and-the-dunes'
import { operationTripleTake } from './operation-triple-take'
import { playingForEternity } from './playing-for-eternity'
import { pocketOfHope } from './pocket-of-hope'
import { possumTrot } from './possum-trot'
import { quitters } from './quitters'
import { quokkasWildLife } from './quokkas-wild-life'
import { redemptionOfHenryMyers } from './redemption-of-henry-myers'
import { riot } from './riot'
import { riotAndTheDanceSeries } from './riot-and-the-dance-series'
import { ruleBreakers } from './rule-breakers'
import { sabina } from './sabina'
import { sevenDaysInUtopia } from './seven-days-in-utopia'
import { sight } from './sight'
import { sketch } from './sketch'
import { somebodysGottaDoIt } from './somebodys-gotta-do-it'
import { somethingToStandForWithMikeRowe } from './something-to-stand-for-with-mike-rowe'
import { soundOfFreedom } from './sound-of-freedom'
import { soundOfHopeTheStoryOfPossumTrot } from './sound-of-hope-the-story-of-possum-trot'
import { standingUp } from './standing-up'
import { surprisedByOxford } from './surprised-by-oxford'
import { sweetwater } from './sweetwater'
import { telegram } from './telegram'
import { tempest } from './tempest'
import { testament } from './testament'
import { testamentSeries } from './testament-series'
import { testifyAVisionToDieFor } from './testify-a-vision-to-die-for'
import { theBlind } from './the-blind'
import { theChosen } from './the-chosen'
import { theGermanKing } from './the-german-king'
import { theLastRifleman } from './the-last-rifleman'
import { theLunaticFarmer } from './the-lunatic-farmer'
import { thePowerOfAPrayingWife } from './the-power-of-a-praying-wife'
import { theShift } from './the-shift'
import { theWay } from './the-way'
import { toEndAllWars } from './to-end-all-wars'
import { triumphOfTheHeart } from './triumph-of-the-heart'
import { truthAndConviction } from './truth-and-conviction'
import { truthAndTreason } from './truth-and-treason'
import { truthAndTreasonSeries } from './truth-and-treason-series'
import { tuttleTwins } from './tuttle-twins'
import { tuttleTwinsMinisodes } from './tuttle-twins-minisodes'
import { ProjectTheme } from './types'
import { unexplainable } from './unexplainable'
import { vindication } from './vindication'
import { wayfinders } from './wayfinders'
import { wingfeather } from './wingfeather-saga'
import { theWorldWeMake } from './world-we-make'
import { youngWashington } from './young-washington'
import { youngDavid } from './youngDavid'
import { zeroAd } from './zero-ad'

export const ProjectToGradient: Record<string, string> = {
  [slugs.theChosen]: 'bg-brown-transparent-t-t',
  [slugs.tuttleTwins]: 'bg-teal-transparent-t-t',
  [slugs.freelancers]: 'bg-black-transparent-t-t',
  [slugs.dryBar]: 'bg-purple-transparent-t-t',
}

// NOTE This projectThemes object is hopefully a temporary stop-gap.
// The goal is to someday retrieve projectThemes from an API. Theme
// colors for a project should be considered metadata about a project,
// along with title, description, etc.
export const projectThemes: { default: ProjectTheme; [key: string]: ProjectTheme } = {
  default: angelStudios,
  [slugs.afterDeath]: afterDeath,
  [slugs.angelGuild]: angelGuild,
  [slugs.angelStudios]: angelStudios,
  [slugs.aWeekAwaySeries]: aWeekAwaySeries,
  [slugs.axiom]: axiom,
  [slugs.bethlehem]: bethlehem,
  [slugs.betweenBorders]: betweenBorders,
  [slugs.beyondTheMedal]: beyondTheMedal,
  [slugs.bonhoeffer]: bonhoeffer,
  [slugs.braveTheDark]: braveTheDark,
  [slugs.breakingIntoBeautiful]: breakingIntoBeautiful,
  [slugs.cabrini]: cabrini,
  [slugs.david]: david,
  [slugs.dryBar]: dryBar,
  [slugs.dryBarToons]: dryBarToons,
  [slugs.fablehaven]: fablehaven,
  [slugs.faithOfAngels]: faithOfAngels,
  [slugs.forKingAndCountryADrummerBoyChristmasLive]: forKingAndCountryADrummerBoyChristmasLive,
  [slugs.forYourConsideration]: angelStudios,
  [slugs.freelancers]: freelancers,
  [slugs.gabrielAndTheGuardians]: gabrielAndTheGuardians,
  [slugs.godMadeCrab]: godMadeCrab,
  [slugs.godspeed]: godspeed,
  [slugs.goodThings]: goodThings,
  [slugs.hisOnlySon]: hisOnlySon,
  [slugs.homestead]: homestead,
  [slugs.homesteadFamilySurvival]: homesteadFamilySurvival,
  [slugs.homesteadSeries]: homesteadSeries,
  [slugs.homeToHarmony]: homeToHarmony,
  [slugs.hoovey]: hoovey,
  [slugs.inLawfullyYours]: inLawfullyYours,
  [slugs.insideAngelStudios]: insideAngelStudios,
  [slugs.jacob]: jacob,
  [slugs.jungleBeat]: jungleBeat,
  [slugs.justLetGo]: justLetGo,
  [slugs.kingOfKings]: kingOfKings,
  [slugs.lightwise]: lightwise,
  [slugs.likeDandelionDust]: likeDandelionDust,
  [slugs.liveNotByLies]: liveNotByLies,
  [slugs.lostOnAMountainInMaine]: lostOnAMountainInMaine,
  [slugs.miracles]: miracles,
  [slugs.mosaicChurch]: mosaicChurch,
  [slugs.myBrothersKeeper]: myBrothersKeeper,
  [slugs.offTheCouchIntoCreation]: offTheCouchIntoCreation,
  [slugs.oldManAndTheDunes]: oldManAndTheDunes,
  [slugs.operationTripleTake]: operationTripleTake,
  [slugs.playingForEternity]: playingForEternity,
  [slugs.pocketOfHope]: pocketOfHope,
  [slugs.possumTrot]: possumTrot,
  [slugs.quitters]: quitters,
  [slugs.quokkasWildLife]: quokkasWildLife,
  [slugs.redemptionOfHenryMyers]: redemptionOfHenryMyers,
  [slugs.riot]: riot,
  [slugs.riotAndTheDanceSeries]: riotAndTheDanceSeries,
  [slugs.ruleBreakers]: ruleBreakers,
  [slugs.sabina]: sabina,
  [slugs.sevenDaysInUtopia]: sevenDaysInUtopia,
  [slugs.sight]: sight,
  [slugs.sketch]: sketch,
  [slugs.somebodysGottaDoIt]: somebodysGottaDoIt,
  [slugs.somethingToStandForWithMikeRowe]: somethingToStandForWithMikeRowe,
  [slugs.soundOfFreedom]: soundOfFreedom,
  [slugs.soundOfHopeTheStoryOfPossumTrot]: soundOfHopeTheStoryOfPossumTrot,
  [slugs.standingUp]: standingUp,
  [slugs.stagingForContent]: angelStudios,
  [slugs.surprisedByOxford]: surprisedByOxford,
  [slugs.sweetwater]: sweetwater,
  [slugs.tempest]: tempest,
  [slugs.testament]: testament,
  [slugs.testamentSeries]: testamentSeries,
  [slugs.testifyAVisionToDieFor]: testifyAVisionToDieFor,
  [slugs.the1916Project]: the1916Project,
  [slugs.theBlind]: theBlind,
  [slugs.theChosen]: theChosen,
  [slugs.fellowship]: fellowship,
  [slugs.telegram]: telegram,
  [slugs.theGardenCartoon]: theGardenCartoon,
  [slugs.theGermanKing]: theGermanKing,
  [slugs.theHidingPlace]: theHidingPlace,
  [slugs.theLastChampion]: theLastChampion,
  [slugs.theLastRifleman]: theLastRifleman,
  [slugs.theLastRodeo]: theLastRodeo,
  [slugs.theLunaticFarmer]: theLunaticFarmer,
  [slugs.thePowerOfAPrayingWife]: thePowerOfAPrayingWife,
  [slugs.theShift]: theShift,
  [slugs.theWay]: theWay,
  [slugs.theWorldWeMake]: theWorldWeMake,
  [slugs.toEndAllWars]: toEndAllWars,
  [slugs.triumphOfTheHeart]: triumphOfTheHeart,
  [slugs.truthAndConviction]: truthAndConviction,
  [slugs.truthAndTreason]: truthAndTreason,
  [slugs.truthAndTreasonSeries]: truthAndTreasonSeries,
  [slugs.tuttleTwins]: tuttleTwins,
  [slugs.tuttleTwinsMinisodes]: tuttleTwinsMinisodes,
  [slugs.twentyThreeBlast]: twentyThreeBlast,
  [slugs.unexplainable]: unexplainable,
  [slugs.vindication]: vindication,
  [slugs.wayfinders]: wayfinders,
  [slugs.wingfeatherSaga]: wingfeather,
  [slugs.youngDavid]: youngDavid,
  [slugs.youngWashington]: youngWashington,
  [slugs.zeroAd]: zeroAd,
}

export function getProjectTheme(projectSlug?: string | null): ProjectTheme {
  if (!projectSlug) return projectThemes?.default

  const projectTheme = projectThemes[projectSlug]
  if (projectTheme) return projectTheme

  return projectThemes.default
}

export function getProjectThemes(): ProjectTheme[] {
  return Object.values(projectThemes)
}
