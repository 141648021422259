import { slugs } from '@/constants'
import { DEFAULT_FILTER_MIN_CHARS, DEFAULT_STORIES_TO_SHOW, ProjectTheme, StorySort } from './types'

export const testifyAVisionToDieFor: ProjectTheme = {
  slug: slugs.testifyAVisionToDieFor,
  name: 'Testify: A Vision To Die For',
  logo: 'v1658210150/angel-studios/themes/tuttle-twins/logo_subscription.webp',
  lightTheme: {
    themeName: 'light',
    colors: {
      primary: '#FBC22D',
      onPrimary: '#000000',
      secondary: '#B87B5D',
      onSecondary: '#000000',
      tertiary: '#F5EACC',
      onTertiary: '#000000',
    },
  },
  darkTheme: {
    themeName: 'dark',
    colors: {
      primary: '#F5EACC',
      onPrimary: '#000000',
      secondary: '#B87B5D',
      onSecondary: '#000000',
      tertiary: '#FBC22D',
      onTertiary: '#000000',
    },
  },
  payItForward: {
    logo: {
      cloudinaryPath: 'v1651006086/angel-studios/themes/tuttle-twins/logo_pif',
      height: 200,
      width: 300,
    },
    logoOverlayClassName: 'w-1/4',
  },
  stories: {
    filterMinChars: DEFAULT_FILTER_MIN_CHARS,
    sort: StorySort.NEWEST,
    numStories: DEFAULT_STORIES_TO_SHOW,
    allowShareStory: false,
  },
  merch: {
    storeUrl: 'https://angel-studios-inc.myshopify.com/api/2022-07/graphql.json',
    accessToken: 'ba113b3c3998ae023db05e42a85a6ac8',
    handle: 'tuttle-twins',
  },
}
